<template>
    <page-title
        title="予定のたまごアラート"
        icon="bi-egg-fried"
    >
        <button-back
            :to="{name: 'Schedule'}"
            text="スケジュールへ戻る"
        ></button-back>
    </page-title>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <div class="text-end mb-3">
                <router-link :to="{name: 'UndecidedSchedule'}">
                    予定のたまごの全リストはこちら
                </router-link>
            </div>
            <template v-if="undecided_schedules.length">
                <div class="alert alert-danger mb-3" role="alert">
                    <i class="bi bi-exclamation-triangle me-2"></i>次回推定日が本日より90日以内になりましたが、スケジュールに登録されていません。今すぐ確認してください。
                </div>
                <table class="table table-striped mb-4">
                    <thead>
                        <tr class="table-primary">
                            <th class="col-3 text-center">次回推定日</th>
                            <th class="col-5 text-center">学校名</th>
                            <th class="col-6 text-center">予定名</th>
                            <th class="col-3 text-center">前回実施日</th>
                            <th class="col-6"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="undecided_schedule, index in undecided_schedules" :key="undecided_schedule">
                            <tr :class="{'table-warning': !isThisYear(undecided_schedule)}">
                                <td class="text-center">{{ undecided_schedule.estimated_schedule_date_display }}</td>
                                <td class="text-center">{{ undecided_schedule.school.school_name }}</td>
                                <td class="text-center">{{ undecided_schedule.event.event_name }}</td>
                                <td class="text-center">{{ undecided_schedule.last_schedule.schedule_date_display }}</td>
                                <td class="text-center d-flex justify-content-around">
                                    <button-exec
                                        text="本登録..."
                                        color="btn-primary"
                                        icon="bi-box-arrow-up-left"
                                        @click="openScheduleModal(undecided_schedule); setTargetUndecidedSchedule(undecided_schedule, index)"
                                    ></button-exec>
                                    <button-exec
                                        text="中止..."
                                        color="btn-outline-danger"
                                        icon="bi-dash-circle"
                                        @click="$refs.confirm_cancel.show(); setTargetUndecidedSchedule(undecided_schedule, index)"
                                    ></button-exec>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </template>
            <template v-else>
                <alert-no-record
                    text="現在アラートはありません"
                ></alert-no-record>
            </template>
        </template>
    </section>

    <confirm-dialog ref="confirm_cancel" @ok="cancel()">
        <p>この行事を中止してよろしいですか？</p>
    </confirm-dialog>

    <ScheduleModal
        v-if="modal_show"
        v-model="schedule"
        modal_title="スケジュール 本登録"
        @close="toggleModal()"
        @create="remove"
        :equipments="equipments"
        :cars="cars"
        :employees="employees"
        :options_school="options_school"
        :options_employee="options_employee"
        :options_employee_photographer="options_employee_photographer"
        :options_studio="options_studio"
    ></ScheduleModal>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import ScheduleModal from '@/components/schedule/ScheduleModal';
import InlineLoader from '@/components/tools/InlineLoader';
import AlertNoRecord from '@/components/tools/AlertNoRecord';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import School from '@/models/entities/school';
import Event from '@/models/entities/event';
import Equipment from '@/models/entities/equipment';
import Employee from '@/models/entities/employee';
import Car from '@/models/entities/car';
import UndecidedSchedule from '@/models/entities/undecided-schedule';
import Schedule from '@/models/entities/schedule';
import IsPhotographer from '@/models/enums/employee/is-photographer';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonBack from '@/components/buttons/ButtonBack';

export default {
    name: 'AlertUndecidedSchedule',
    components: {
        PageTitle,
        ScheduleModal,
        InlineLoader,
        AlertNoRecord,
        ConfirmDialog,
        ButtonExec,
        ButtonBack,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,

            photographer_id: this.$route.params.photographer_id,
            undecided_schedules: [],

            //モーダル用
            cars: [],
            equipments: [],
            employees: [],
            options_school: [],
            options_employee: [],
            options_employee_photographer: [],
            options_studio: [],

            target_index: null,
            target_undefined_schedule: new UndecidedSchedule(),

            schedule: new Schedule(),
            modal_show: false,
        }
    },
    mounted() {
        this.fetchAllSchools();
        this.fetchCarList();
        this.fetchEquipmentList();
        this.fetchAlertUndecidedScheduleList();
        this.fetchAllStudiosForOptions();
    },
    methods: {
        fetchAllSchools() {
            this.loading++;
            this.$http.get('/schools/all')
            .then(response => {
                for (let row of response.data) {
                    this.options_school.push({key: row.school_id, label: row.school_name})
                }
                this.fetchAllEmployees();
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 全ての社員取得
        fetchAllEmployees() {
            this.loading++;

            this.$http.get('/employees/all')
            .then(response => {
                for (let row of response.data) {
                    let employee = new Employee(row);
                    this.employees.push(employee);
                    this.options_employee.push({key: employee.photographer_id, label: employee.photographer_name});
                    // カメラマンの社員のみ
                    if (employee.is_photographer === IsPhotographer.PHOTOGRAPHER) {
                        this.options_employee_photographer.push({key: employee.photographer_id, label: employee.photographer_name});
                    }
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        fetchCarList() {
            this.loading++;
            this.$http.get(`/cars`)
            .then(response => {
                for (let row of response.data) {
                    this.cars.push(new Car(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        fetchEquipmentList() {
            this.loading++;
            this.$http.get('/equipments')
            .then(response => {
                for (let row of response.data) {
                    this.equipments.push(new Equipment(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        fetchAlertUndecidedScheduleList() {
            this.loading++;

            this.$http.get('/alerts/undecided-schedules', {
                params: {
                    photographer_id: this.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.undecided_schedules.push(new UndecidedSchedule(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 店舗を取得してoptions用に整形
        fetchAllStudiosForOptions() {
            this.options_studio.splice(0);
            this.$http.get('/studios')
            .then(response => {
                for (let row of response.data) {
                    this.options_studio.push({key: row.studio_id, label: row.studio_name});
                }
            });
        },
        // 今年度かどうか(年度 = 日付の3ヶ月前の年)
        isThisYear(undecided_schedule) {
            // 今年度
            let date = new Date();
            date.setMonth(date.getMonth() - 3);
            let this_year = date.getFullYear();

            // 次回推定日の年度
            let target_date = new Date(undecided_schedule.estimated_schedule_date);
            target_date.setMonth(target_date.getMonth() - 3);
            let target_year = target_date.getFullYear()

            if (this_year === target_year) {
                return true;
            }

            return false
        },
        resetSchedule() {
            this.schedule.schedule_id = null;
            this.schedule.school = new School();
            this.schedule.event = new Event();
            this.schedule.schedule_type = null;
            this.schedule.schedule_date = null;
            this.schedule.hour_from = null;
            this.schedule.minute_from = null;
            this.schedule.hour_to = null;
            this.schedule.minute_to = null;
            this.schedule.is_unfixed = 0;
            this.schedule.unfixed_type = null;
            this.schedule.has_several_days = 0;
            this.schedule.first_schedule = null;
            this.schedule.end_date = null;
            this.schedule.end_hour = null;
            this.schedule.end_minute = null;
            this.schedule.rain_type = null;
            this.schedule.spare_schedule = new Schedule();
            this.schedule.checked_date = null;
            this.schedule.photographer_number = null;
            this.schedule.transportation = null;
            this.schedule.schedule_place = null;
            this.schedule.contact_person = null;
            this.schedule.contact_tel = null;
            this.schedule.note = null;
            this.schedule.cars.splice(0);
            this.schedule.files.splice(0);
            this.schedule.memos.splice(0);
            this.schedule.equipments.splice(0);
            this.schedule.photographers.splice(0);
            this.schedule.histories.splice(0);
        },
        openScheduleModal(undecided_schedule) {
            this.resetSchedule();
            this.schedule.school = undecided_schedule.school;
            this.schedule.event = undecided_schedule.event;
            this.toggleModal();
        },
        setTargetUndecidedSchedule(undecided_schedule = new UndecidedSchedule(), index = null) {
            this.target_index = index;
            this.target_undefined_schedule = undecided_schedule;
        },
        cancel() {
            this.startScreenLoading();

            this.$http.delete(`/undecided-schedules/${this.target_undefined_schedule.undecided_schedule_id}`)
            .then(() => {
                this.showMessage('今年度の行事を中止にしました');
                this.undecided_schedules.splice(this.target_index, 1);
                this.setTargetUndecidedSchedule();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/undecided-schedules/${this.target_undefined_schedule.undecided_schedule_id}`)
            .then(() => {
                this.undecided_schedules.splice(this.target_index, 1);
                this.setTargetUndecidedSchedule();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        toggleModal() {
            if (this.modal_show) {
                this.modal_show = false;
            } else {
                this.modal_show = true;
            }
        },
    }
}
</script>

<style scoped>

</style>
